import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';

const MyTokensPage = ({ realEstateInstance, contractOwner }) => {
  return (
    <div className="admin-page">
      <h1 className="admin-page-title">NFT Holder Management</h1>

      <Routes>
        <Route path="/" element={<Dashboard realEstateInstance={realEstateInstance} contractOwner={contractOwner} />} />
      </Routes>
    </div>
  );
};

export default MyTokensPage;
