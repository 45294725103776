import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutUsPage.css'; // Import the entire CSS file

const AboutUsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="whitepaper-page">
      <h1 className="whitepaper-page-title">About Us</h1>

      {/* Navigation Bar */}
      <nav className="whitepaper-page-nav">
        <ul>
          <li><button onClick={() => navigate('/')} className="nav-link">Back to Main Page</button></li>
        </ul>
      </nav>

      {/* Our Mission Section */}
      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>At Ant Tokenizations, we are dedicated to revolutionizing the real estate and agricultural sectors through the integration of cutting-edge technologies such as blockchain, AI, drone technology, and smart contracts. Our mission is to create a sustainable, secure, and inclusive ecosystem for land management and investment, enabling fractional ownership and fostering transparency.</p>
      </section>

      {/* Who We Are Section */}
      <section className="who-we-are-section">
        <h2>Who We Are</h2>
        <p>We are a team of passionate innovators, technologists, and industry experts committed to transforming the way land ownership and management are conducted. Our diverse backgrounds and expertise allow us to approach challenges from multiple perspectives, driving innovation and delivering impactful solutions.</p>
      </section>

      {/* What We Do Section */}
      <section className="what-we-do-section">
        <h2>What We Do</h2>
        <p>We leverage advanced technologies to provide a comprehensive platform for real estate and agricultural investments. Our solutions include:</p>
        <ul>
          <li><strong>Blockchain Technology:</strong> Ensuring secure, transparent, and immutable records of property ownership and transactions.</li>
          <li><strong>AI Integration:</strong> Providing data-driven insights into land productivity, property valuation, and predictive analytics.</li>
          <li><strong>Drone Technology:</strong> Offering real-time, precise data collection for superior land and crop management.</li>
          <li><strong>Smart Contracts:</strong> Automating transactions and governance, ensuring security and transparency.</li>
        </ul>
      </section>

      {/* Our Vision Section */}
      <section className="vision-section">
        <h2>Our Vision</h2>
        <p>Our vision is to democratize access to real estate and farmland investments, making these markets accessible to a broader audience. By tokenizing assets as NFTs, we enable fractional ownership, create transparency, and foster a sustainable, secure, and inclusive ecosystem for land management and investment.</p>
      </section>

      {/* Our Values Section */}
      <section className="values-section">
        <h2>Our Values</h2>
        <ul>
          <li><strong>Innovation:</strong> We are committed to continuous innovation, leveraging the latest technologies to drive progress and deliver value.</li>
          <li><strong>Transparency:</strong> We believe in transparency and accountability, ensuring that all transactions and data exchanges are secure and verifiable.</li>
          <li><strong>Inclusivity:</strong> We strive to create an inclusive ecosystem that empowers individuals and communities, promoting local development and providing opportunities for all.</li>
          <li><strong>Sustainability:</strong> We are dedicated to promoting sustainable practices in real estate and agriculture, contributing to global food security and environmental conservation.</li>
        </ul>
      </section>

      {/* Meet Our Team Section */}
      <section className="team-section">
        <h2>Meet Our Team</h2>
        <p>Our team is composed of talented professionals with diverse backgrounds and expertise. From AI developers and drone operators to blockchain specialists and real estate experts, we work collaboratively to achieve our mission and vision.</p>
      </section>
    </div>
  );
};

export default AboutUsPage;
