 // App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { initializeWeb3, isWeb3Enabled } from './web3';
import './App.css';
import logo from './_9f84e138-063e-4d84-930b-90baecaefd23.jpeg';

import Home from './HomePage';
import Tokenization from './TokenizationPage';
import MarketPlace from './MarketPlacePage';
import Whitepaper from './WhitepaperPage'; 
import DroneAI from './DroneAiPage'; 
import AboutUs from './AboutUsPage'; 
import ConnectWallet from './ConnectWalletPage';
import MyTokens from './MyTokensPage';
import VisualizationPage from './VisualizationPage'; // Import the VisualizationPage component
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import RegistrationPage from './RegistrationPage';

const ProtectedRoute = ({ element, setMarketplaceInstance, setRealEstateInstance, setErc20Instances, setErc721Instance, setNftWithIPNSInstance, setContractOwner }) => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkWalletConnection = async () => {
      const web3Enabled = await isWeb3Enabled();
      if (web3Enabled) {
        // Initialize Web3 and contracts
        initializeWeb3(setIsLoading)
          .then(({ marketplaceInstance, realEstateInstance, erc20Instances, erc721Instance, nftWithIPNSInstance }) => {
            setMarketplaceInstance(marketplaceInstance);
            setRealEstateInstance(realEstateInstance);
            setErc20Instances(erc20Instances);
            setErc721Instance(erc721Instance);
            setNftWithIPNSInstance(nftWithIPNSInstance);
            setIsWalletConnected(true);
            setIsLoading(false);
          })
          .catch(err => {
            console.error(err);
            setIsLoading(false);
          });
      } else {
        setIsWalletConnected(false);
        setIsLoading(false);
      }
    };

    checkWalletConnection();
  }, [setMarketplaceInstance, setRealEstateInstance, setErc20Instances, setErc721Instance, setNftWithIPNSInstance, setContractOwner]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isWalletConnected ? element : <ConnectWallet />;
};


const App = () => {
  const [marketplaceInstance, setMarketplaceInstance] = useState(null);
  const [realEstateInstance, setRealEstateInstance] = useState(null);
  const [erc20Instances, setErc20Instances] = useState({});
  const [erc721Instance, setErc721Instance] = useState(null);
  const [nftWithIPNSInstance, setNftWithIPNSInstance] = useState(null);
  const [contractOwner, setContractOwner] = useState('');
 const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const theme = createTheme();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check initial screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

 return (
  <ThemeProvider theme={theme}>
    <Router>
      <div className="App">
        <nav>
          <div className={isMobile ? "mobile-logo" : "logo"}>
            <Link to="/"><img src={logo} alt="Logo" /></Link>
          </div>
          {isMobile ? (
            <div className="menu-button" onClick={toggleMenu}>
              ☰
            </div>
          ) : (
            <ul className="navbar">
              <li className="nav-item">
                <Link to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/Whitepaper">Whitepaper</Link>
              </li>
              <li className="nav-item">
                <Link to="/DroneAi">DroneAI</Link>
              </li>
              <li className="nav-item">
                <Link to="/tokenization">Tokenization</Link>
              </li>
              <li className="nav-item">
  <Link to="/mytokens">MyTokensPage</Link>
</li>
              <li className="nav-item">
                <Link to="/Marketplace">Marketplace</Link>
              </li>
              <li className="nav-item">
                <Link to="/AboutUs">AboutUs</Link>
              </li>
            </ul>
          )}
          {isMobile && isOpen && (
            <ul className="mobile-navbar">
              <li className="mobile-nav-item">
                <Link to="/" onClick={toggleMenu}>Home</Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/Whitepaper" onClick={toggleMenu}>Whitepaper</Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/DroneAi" onClick={toggleMenu}>DroneAI</Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/tokenization" onClick={toggleMenu}>Tokenization</Link>
              </li>
               <li className="mobile-nav-item">
                <Link to="/mytokens" onClick={toggleMenu}>MyTokens</Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/Marketplace" onClick={toggleMenu}>Marketplace</Link>
              </li>
              <li className="mobile-nav-item">
                <Link to="/AboutUs" onClick={toggleMenu}>AboutUs</Link>
              </li>
            </ul>
          )}
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Whitepaper/*" element={<Whitepaper />} />
          <Route path="/DroneAI/*" element={<DroneAI />} />
          <Route path="/AboutUs/*" element={<AboutUs />} />
          <Route path="/tokenization/*" element={<ProtectedRoute 
            element={<Tokenization />} 
            setMarketplaceInstance={setMarketplaceInstance}
            setRealEstateInstance={setRealEstateInstance}
            setErc20Instances={setErc20Instances}
            setErc721Instance={setErc721Instance}
            setNftWithIPNSInstance={setNftWithIPNSInstance}
            setContractOwner={setContractOwner}
          />} />
          <Route path="/mytokens/*" element={<ProtectedRoute 
  element={<MyTokens />} 
  setMarketplaceInstance={setMarketplaceInstance}
  setRealEstateInstance={setRealEstateInstance}
  setErc20Instances={setErc20Instances}
  setErc721Instance={setErc721Instance}
  setNftWithIPNSInstance={setNftWithIPNSInstance}
  setContractOwner={setContractOwner}
/>} />
          <Route path="/ConnectWallet/*" element={<ConnectWallet />} />
          <Route path="/marketplace/*" element={<ProtectedRoute 
            element={<MarketPlace />} 
            setMarketplaceInstance={setMarketplaceInstance}
            setRealEstateInstance={setRealEstateInstance}
            setErc20Instances={setErc20Instances}
            setErc721Instance={setErc721Instance}
            setNftWithIPNSInstance={setNftWithIPNSInstance}
            setContractOwner={setContractOwner}
          />} />
          {/* Add the new route for VisualizationPage */}
          <Route path="/visualization/:tokenId" element={<VisualizationPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/RegistrationPage" element={<RegistrationPage />} />
          <Route path="/registration" element={<RegistrationPage />} /> {/* Add this line */}
        </Routes>

        <Footer /> {/* Add the Footer component here */}
      </div>
    </Router>
  </ThemeProvider>
  );
};

export default App;
