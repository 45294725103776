import React from 'react';
import Section from './Section';
import BalanceOf from './BalanceOf';
import OwnerOf from './OwnerOf';
import Approve from './Approve';
import SetApprovalForAll from './SetApprovalForAll';
import IsApprovedForAll from './IsApprovedForAll';
import TransferFrom from './TransferFrom';
import './ManageTokens.css';

const Card = ({ title, children }) => (
  <div className="token-management-card">
    <h3>{title}</h3>
    {children}
  </div>
);

const TokenManagement = ({ realEstateInstance, contractOwner }) => {
  return (
    <Section title="Token Management">
      <div className="token-management-container">
        <Card title="Token Information">
          <BalanceOf contractInstance={realEstateInstance} contractOwner={contractOwner} />
          <OwnerOf contractInstance={realEstateInstance} />
        </Card>
        <Card title="Approvals">
          <Approve contractInstance={realEstateInstance} />
          <SetApprovalForAll contractInstance={realEstateInstance} />
          <IsApprovedForAll contractInstance={realEstateInstance} />
        </Card>
        <Card title="Transfer">
          <TransferFrom contractInstance={realEstateInstance} />
        </Card>
      </div>
    </Section>
  );
};

export default TokenManagement;

