import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const IsApprovedForAll = () => {
  const [owner, setOwner] = useState('');
  const [operator, setOperator] = useState('');
  const [isApproved, setIsApproved] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();
  }, []);

  const handleIsApprovedForAll = async (e) => {
    e.preventDefault();

    if (!owner || !operator) {
      setErrorMessage('Owner and Operator addresses are required.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setIsApproved(null);

    try {
      // Ensure contractInstance is available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      const result = await contractInstances.realEstateInstance.methods.isApprovedForAll(owner, operator).call();
      setIsApproved(result);
    } catch (error) {
      console.error(`Error checking approval: ${error.message}`);
      setErrorMessage(`Error checking approval: ${error.message}`);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Is Approved For All</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleIsApprovedForAll}>
          <div>
            <label>
              Owner Address:
              <input
                type="text"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Operator Address:
              <input
                type="text"
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Check Approval'}
            </button>
          </div>
          {isApproved !== null && <p>Is Approved: {isApproved ? 'Yes' : 'No'}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default IsApprovedForAll;
