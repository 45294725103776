import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { initializeWeb3 } from './web3';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  IconButton,
  Tooltip,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Alert,
  TableContainer,
  Button,
  CardActions,
  Drawer,
  Fab,
  Pagination,
} from '@mui/material';
import { Refresh as RefreshIcon, Apple as AppleIcon, AttachMoney as MoneyIcon, Settings as SettingsIcon, Menu as MenuIcon, ChevronLeft, ChevronRight } from '@mui/icons-material';
import AiRequest from './AiRequest';
import './Dashboard.css';
import { io } from 'socket.io-client';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ManageTokens from './ManageTokens';
import ManageProposals from './ManageProposals';
import SeasonFeeManagement from './SeasonFeeManagement';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DashboardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  position: 'relative',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const NftCard = React.memo(({ nft, aiResults, setAiResults, theme }) => {
  const aiResult = aiResults[nft.tokenId];

  const handleAiResultsReady = useCallback((results) => {
    setAiResults(prevResults => ({
      ...prevResults,
      [nft.tokenId]: results
    }));
  }, [nft.tokenId, setAiResults]);

  return (
    <Card>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }}>{nft.tokenId}</Avatar>}
        title={`NFT #${nft.tokenId}`}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="body2" color="text.secondary">
          IPNS Link: {nft.ipnsLink}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <LazyLoadImage
            alt={`NFT ${nft.tokenId}`}
            height={200}
            src={`https://ipfs.io/ipfs/${nft.ipnsLink}`}
            width="100%"
            effect="blur"
            style={{ objectFit: 'cover', borderRadius: theme.shape.borderRadius }}
          />
        </Box>
        {aiResult && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>AI Analysis Results</Typography>
            <Typography variant="body2">Total Apple Count: {aiResult.totalAppleCount}</Typography>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <AiRequest 
            tokenId={nft.tokenId.toString()} 
            onResultsReady={handleAiResultsReady}
          />
        </Box>
      </CardContent>
    </Card>
  );
});

const SettingsModal = React.memo(({ open, handleClose, userSettings, handleSettingsChange }) => (
  <Modal open={open} onClose={handleClose}>
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
      <Typography variant="h6" component="h2">
        Dashboard Settings
      </Typography>
      {Object.entries(userSettings).map(([key, value]) => (
        <FormControlLabel
          key={key}
          control={<Switch checked={value} onChange={() => handleSettingsChange(key)} />}
          label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
        />
      ))}
    </Box>
  </Modal>
));

const Dashboard = () => {
  const [account, setAccount] = useState('');
  const [contractInstances, setContractInstances] = useState(null);
  const [userNfts, setUserNfts] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractName, setContractName] = useState('');
  const [contractSymbol, setContractSymbol] = useState('');
  const [proposals, setProposals] = useState([]);
  const [aiResults, setAiResults] = useState({});
  const [filterConfig, setFilterConfig] = useState({ key: 'tokenId', value: '' });
  const [userSettings, setUserSettings] = useState({
    showFinancialSummary: true,
    showAppleAnalysis: true,
    showNftOverview: true,
    showNftComparison: true,
    showFinancialDistribution: true,
  });
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedNftForAnnotation, setSelectedNftForAnnotation] = useState(null);
  const [page, setPage] = useState(1);
  const [nftsPerPage] = useState(12);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [activeManagementTab, setActiveManagementTab] = useState(null);
  const [managementModalOpen, setManagementModalOpen] = useState(false);
  const [nftComparisonRange, setNftComparisonRange] = useState({ start: 0, end: 10 });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchUserData = useCallback(async (contractInstances, userAddress) => {
    try {
      setIsLoading(true);
      const userInfo = await contractInstances.realEstateInstance.methods.getUserInfo(userAddress).call();
      const { tokenIds, userIpnsLinks, values, totalIncome } = userInfo;
      const nfts = tokenIds.map((tokenId, index) => ({
        tokenId: Number(tokenId),
        mintValue: Number(values[index]),
        ipnsLink: userIpnsLinks[index],
      }));
      setUserNfts(nfts);
      setTotalIncome(Number(totalIncome));
    } catch (error) {
      console.error('Error fetching user data:', error);
      setErrorMessage('Failed to fetch user data: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchContractInfo = useCallback(async (contractInstances) => {
    try {
      const name = await contractInstances.realEstateInstance.methods.name().call();
      const symbol = await contractInstances.realEstateInstance.methods.symbol().call();
      setContractName(name);
      setContractSymbol(symbol);
    } catch (error) {
      console.error('Error fetching contract info:', error);
    }
  }, []);

  const fetchProposals = useCallback(async (contractInstances) => {
    try {
      const userProposals = [];
      let i = 0;
      while (true) {
        try {
          const proposal = await contractInstances.realEstateInstance.methods.proposals(i).call();
          userProposals.push({
            id: i,
            proposalType: proposal.proposalType,
            description: proposal.description,
            amount: Number(proposal.amount),
            recipient: proposal.recipient,
            totalVotesFor: Number(proposal.totalVotesFor),
            totalVotesAgainst: Number(proposal.totalVotesAgainst),
            active: proposal.active,
            deadline: Number(proposal.deadline),
          });
          i++;
        } catch (error) {
          break;
        }
      }
      setProposals(userProposals);
    } catch (error) {
      console.error('Error fetching proposals:', error);
      setErrorMessage('Failed to fetch proposals: ' + error.message);
    }
  }, []);

  useEffect(() => {
    const initWeb3 = async () => {
      try {
        const instances = await initializeWeb3(setIsLoading);
        setContractInstances(instances);
      } catch (error) {
        console.error('Error initializing Web3:', error);
        setErrorMessage('Failed to initialize Web3: ' + error.message);
      }
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        setErrorMessage('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        setErrorMessage('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Set up WebSocket connection
    const socket = io('YOUR_WEBSOCKET_SERVER_URL');
    socket.on('dataUpdate', (data) => {
      setUserNfts(data.userNfts);
      setTotalIncome(data.totalIncome);
      setProposals(data.proposals);
    });

    return () => {
      window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      socket.disconnect();
    };
  }, [account]);

  useEffect(() => {
    if (account && contractInstances) {
      fetchUserData(contractInstances, account);
      fetchContractInfo(contractInstances);
      fetchProposals(contractInstances, account);
    }
  }, [account, contractInstances]);

  const refreshData = useCallback(async () => {
    if (account && contractInstances) {
      setIsRefreshing(true);
      try {
        await fetchUserData(contractInstances, account);
        await fetchProposals(contractInstances, account);
      } catch (error) {
        console.error('Error refreshing data:', error);
        setErrorMessage('Failed to refresh data: ' + error.message);
      } finally {
        setIsRefreshing(false);
      }
    }
  }, [account, contractInstances, fetchUserData, fetchProposals]);

  const handleFilter = useCallback((event) => {
    setFilterConfig(prev => ({ ...prev, value: event.target.value }));
  }, []);

  const sortedAndFilteredNfts = useMemo(() => {
    let result = [...userNfts];
    
    // Filtering
    if (filterConfig.value) {
      result = result.filter(nft => 
        String(nft[filterConfig.key]).toLowerCase().includes(filterConfig.value.toLowerCase())
      );
    }
    
    return result;
  }, [userNfts, filterConfig]);

  const handleSettingsChange = useCallback((setting) => {
    setUserSettings(prev => ({ ...prev, [setting]: !prev[setting] }));
  }, []);

  const toggleSettings = useCallback(() => {
    setSettingsOpen(prev => !prev);
  }, []);

  const totalApples = useMemo(() => {
    return Object.values(aiResults).reduce((sum, result) => sum + (result.totalAppleCount || 0), 0);
  }, [aiResults]);

  const averageApplesPerNft = useMemo(() => {
    const analyzedNfts = Object.keys(aiResults).length;
    return analyzedNfts > 0 ? totalApples / analyzedNfts : 0;
  }, [totalApples, aiResults]);

  const totalMintValue = useMemo(() => {
    return userNfts.reduce((sum, nft) => sum + Number(nft.mintValue), 0);
  }, [userNfts]);

  const nftComparisonData = useMemo(() => {
    return userNfts
      .slice(nftComparisonRange.start, nftComparisonRange.end)
      .map(nft => ({
        tokenId: nft.tokenId,
        mintValue: Number(nft.mintValue),
        appleCount: aiResults[nft.tokenId]?.totalAppleCount || 0,
      }));
  }, [userNfts, aiResults, nftComparisonRange]);

  const handlePrevNfts = () => {
    setNftComparisonRange(prev => ({
      start: Math.max(0, prev.start - 10),
      end: Math.max(10, prev.end - 10)
    }));
  };

  const handleNextNfts = () => {
    setNftComparisonRange(prev => ({
      start: Math.min(userNfts.length - 10, prev.start + 10),
      end: Math.min(userNfts.length, prev.end + 10)
    }));
  };

  const pieChartData = useMemo(() => [
    { name: 'Total Income', value: totalIncome },
    { name: 'Total Mint Value', value: totalMintValue },
  ], [totalIncome, totalMintValue]);

  const HeaderContent = useMemo(() => (
    <>
      <Box>
        <Tooltip title="Dashboard Settings">
          <IconButton 
            onClick={toggleSettings} 
            size="large"
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography 
        variant="h5" 
        component="h1" 
        sx={{ 
          textAlign: 'center',
        }}
      >
        {contractName} ({contractSymbol})
      </Typography>
      <Box /> {/* Empty box for the right column */}
    </>
  ), [contractName, contractSymbol, toggleSettings]);

  const paginatedNfts = useMemo(() => {
    const startIndex = (page - 1) * nftsPerPage;
    return sortedAndFilteredNfts.slice(startIndex, startIndex + nftsPerPage);
  }, [sortedAndFilteredNfts, page, nftsPerPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const memoizedNftComparisonData = useMemo(() => {
    return nftComparisonData;
  }, [nftComparisonData]);

  const memoizedPieChartData = useMemo(() => {
    return pieChartData;
  }, [pieChartData]);

  const toggleManagementModal = () => {
    setManagementModalOpen(!managementModalOpen);
  };

  const renderManagementContent = () => {
    switch (activeManagementTab) {
      case 'tokens':
        return <ManageTokens realEstateInstance={contractInstances?.realEstateInstance} />;
      case 'proposals':
        return <ManageProposals realEstateInstance={contractInstances?.realEstateInstance} contractOwner={account} />;
      case 'seasonFee':
        return <SeasonFeeManagement realEstateInstance={contractInstances?.realEstateInstance} />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center', 
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 64, // Set a fixed height for the header
      }}>
        {HeaderContent}
      </Box>
      
      <DashboardContainer>
        <ContentContainer>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <Grid container spacing={3}>
            {userSettings.showFinancialSummary && (
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardHeader
                    avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }}><MoneyIcon /></Avatar>}
                    action={
                      <IconButton onClick={refreshData} disabled={isRefreshing}>
                        {isRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
                      </IconButton>
                    }
                    title="Financial Summary"
                  />
                  <CardContent>
                    <Typography variant="h6">Total Income: ${totalIncome.toLocaleString()}</Typography>
                    <Typography variant="h6">Total Mint Value: ${totalMintValue.toLocaleString()}</Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}

            {userSettings.showAppleAnalysis && (
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardHeader
                    avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }}><AppleIcon /></Avatar>}
                    title="Apple Analysis"
                  />
                  <CardContent>
                    <Typography variant="h6">Total Apples: {totalApples}</Typography>
                    <Typography variant="h6">Average Apples per NFT: {averageApplesPerNft.toFixed(2)}</Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}

            {userSettings.showNftOverview && (
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardHeader
                    title="NFT Overview"
                  />
                  <CardContent>
                    <Typography variant="h6">Total NFTs: {userNfts.length}</Typography>
                    <Typography variant="h6">Analyzed NFTs: {Object.keys(aiResults).length}</Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}

            {userSettings.showNftComparison && (
              <Grid item xs={12} md={6}>
                <StyledCard>
                  <CardHeader 
                    title="NFT Comparison" 
                    action={
                      <Box>
                        <IconButton onClick={handlePrevNfts} disabled={nftComparisonRange.start === 0}>
                          <ChevronLeft />
                        </IconButton>
                        <IconButton onClick={handleNextNfts} disabled={nftComparisonRange.end >= userNfts.length}>
                          <ChevronRight />
                        </IconButton>
                      </Box>
                    }
                  />
                  <CardContent>
                    <ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
                      <BarChart data={memoizedNftComparisonData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="tokenId" />
                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                        <RechartsTooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="mintValue" fill="#8884d8" name="Mint Value ($)" />
                        <Bar yAxisId="right" dataKey="appleCount" fill="#82ca9d" name="Apple Count" />
                      </BarChart>
                    </ResponsiveContainer>
                    <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                      Showing NFTs {nftComparisonRange.start + 1} - {Math.min(nftComparisonRange.end, userNfts.length)} of {userNfts.length}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}

            {userSettings.showFinancialDistribution && (
              <Grid item xs={12} md={6}>
                <StyledCard>
                  <CardHeader title="Financial Distribution" />
                  <CardContent>
                    <ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
                      <PieChart>
                        <Pie
                          data={memoizedPieChartData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {memoizedPieChartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <RechartsTooltip formatter={(value) => `$${value.toLocaleString()}`} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <StyledCard>
                <CardHeader title="My NFTs" />
                <CardContent>
                  <Grid container spacing={2}>
                    {paginatedNfts.map((nft) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={nft.tokenId}>
                        <NftCard 
                          nft={nft}
                          aiResults={aiResults}
                          setAiResults={setAiResults}
                          theme={theme}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Pagination 
                      count={Math.ceil(sortedAndFilteredNfts.length / nftsPerPage)} 
                      page={page} 
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} My Farmland NFTs. All rights reserved.
            </Typography>
          </Box>

          <SettingsModal
            open={settingsOpen}
            handleClose={toggleSettings}
            userSettings={userSettings}
            handleSettingsChange={handleSettingsChange}
          />

          {selectedNftForAnnotation && (
            <Modal
              open={!!selectedNftForAnnotation}
              onClose={() => setSelectedNftForAnnotation(null)}
            >
              <Box sx={{ /* style your modal */ }}>
                <Typography variant="h6">Annotated Images for NFT #{selectedNftForAnnotation.tokenId}</Typography>
                {/* Add annotated images or a link to view them here */}
              </Box>
            </Modal>
          )}
        </ContentContainer>
      </DashboardContainer>

      {/* NFT Holder Management Modal */}
      <Modal
        aria-labelledby="nft-management-modal-title"
        aria-describedby="nft-management-modal-description"
        open={managementModalOpen}
        onClose={toggleManagementModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={managementModalOpen}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '90vh',
            overflow: 'auto',
          }}>
            <Typography variant="h6" id="nft-management-modal-title" sx={{ mb: 2 }}>
              NFT Holder Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Button 
                variant={activeManagementTab === 'tokens' ? 'contained' : 'outlined'} 
                onClick={() => setActiveManagementTab('tokens')}
              >
                Manage Tokens
              </Button>
              <Button 
                variant={activeManagementTab === 'proposals' ? 'contained' : 'outlined'} 
                onClick={() => setActiveManagementTab('proposals')}
              >
                Manage Proposals
              </Button>
              <Button 
                variant={activeManagementTab === 'seasonFee' ? 'contained' : 'outlined'} 
                onClick={() => setActiveManagementTab('seasonFee')}
              >
                Season Fee Management
              </Button>
            </Box>
            {renderManagementContent()}
          </Box>
        </Fade>
      </Modal>

      {/* Toggle button for management modal */}
      <Fab 
        color="primary" 
        aria-label="open management"
        onClick={toggleManagementModal}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <MenuIcon />
      </Fab>
    </Box>
  );
};

export default React.memo(Dashboard);

