import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';

const VotingStatus = () => {
  const [contractInstances, setContractInstances] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractId, setContractId] = useState('');
  const [votingStatus, setVotingStatus] = useState(null);
  const [account, setAccount] = useState('');

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleGetVotingStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setVotingStatus(null);

    try {
      if (!contractInstances.marketplaceInstance) {
        throw new Error('Marketplace contract instance is not available.');
      }

      const contractDetails = await contractInstances.marketplaceInstance.methods.getContractFullDetails(contractId).call();
      const currentTimestamp = Math.floor(Date.now() / 1000);

      setVotingStatus({
        totalVotes: contractDetails.totalVotes,
        totalEligibleVotes: contractDetails.totalEligibleVotes,
        votingDeadline: contractDetails.votingDeadline,
        isVotingActive: currentTimestamp < contractDetails.votingDeadline && currentTimestamp >= contractDetails.biddingDeadline,
        timeRemaining: Math.max(0, contractDetails.votingDeadline - currentTimestamp)
      });
    } catch (error) {
      setErrorMessage(`Error fetching voting status: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* <h3></h3> */}
      {isLoading && <p>Loading...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleGetVotingStatus}>
        <input
          type="number"
          placeholder="Contract ID"
          value={contractId}
          onChange={(e) => setContractId(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>Get Voting Status</button>
      </form>
      {votingStatus && (
        <div>
          <p>Total Votes: {votingStatus.totalVotes}</p>
          <p>Total Eligible Votes: {votingStatus.totalEligibleVotes}</p>
          <p>Voting Deadline: {new Date(votingStatus.votingDeadline * 1000).toLocaleString()}</p>
          <p>Voting Active: {votingStatus.isVotingActive ? 'Yes' : 'No'}</p>
          <p>Time Remaining: {Math.floor(votingStatus.timeRemaining / 3600)} hours {Math.floor((votingStatus.timeRemaining % 3600) / 60)} minutes</p>
        </div>
      )}
    </div>
  );
};

export default VotingStatus;