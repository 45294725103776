import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const Approve = () => {
  const [tokenId, setTokenId] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [isApproving, setIsApproving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleApprove = async (e) => {
    e.preventDefault();

    if (!tokenId || !toAddress) {
      setErrorMessage('Both Token ID and address are required.');
      return;
    }

    setIsApproving(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Ensure contractInstance and account are available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      await contractInstances.realEstateInstance.methods.approve(toAddress, tokenId).send({ from: account });

      setSuccessMessage('Approval successful!');
    } catch (error) {
      console.error(`Error approving: ${error.message}`);
      setErrorMessage(`Error approving: ${error.message}`);
    } finally {
      setIsApproving(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Approve Token Transfer</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleApprove}>
          <div>
            <label>
              Token ID:
              <input
                type="text"
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Approve to Address:
              <input
                type="text"
                value={toAddress}
                onChange={(e) => setToAddress(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isApproving}>
              {isApproving ? 'Approving...' : 'Approve'}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default Approve;
