import React from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import './MarketPlacePage.css'; // Import the entire CSS file
import ContractManagement from './ContractManagement';
import BidManagement from './BidManagement';
import VoteManagement from './VoteManagement';

const MarketPlacePage = ({ marketplaceInstance, realEstateInstance, contractOwner }) => {
  const navigate = useNavigate();

  return (
    <div className="marketplace-page">
      <h1 className="marketplace-page-title">Marketplace</h1>

      {/* Desktop and Tablet Navigation */}
      <nav className="marketplace-page-nav">
        <ul>
          <li><Link to="/marketplace" className="nav-link">Main Page</Link></li>
          <li><Link to="/marketplace/contract-management" className="nav-link">Contract Management</Link></li>
          <li><Link to="/marketplace/bid-management" className="nav-link">Bid Management</Link></li>
          <li><Link to="/marketplace/vote-management" className="nav-link">Vote Management</Link></li>
        </ul>
      </nav>

      {/* Mobile Navigation */}
      <nav className="mobile-nav">
        <select onChange={(e) => navigate(e.target.value)} className="mobile-nav-select">
          <option value="/marketplace">Main Page</option>
          <option value="/marketplace/contract-management">Contract Management</option>
          <option value="/marketplace/bid-management">Bid Management</option>
          <option value="/marketplace/vote-management">Vote Management</option>
        </select>
      </nav>

      {/* Introduction Section */}
      <section className="intro-section">
        <h2>Welcome</h2>
        <p>Manage real estate contracts, bids, and votes on our blockchain-powered platform.</p>
      </section>

      {/* Key Features Section */}
      <section className="key-features-section">
        <h2>Key Features</h2>
        <div className="features">
          <div className="feature">
            <i className="icon-contract-management"></i>
            <h3>Contract Management</h3>
            <p>List and manage real estate contracts within the platform.</p>
          </div>
          <div className="feature">
            <i className="icon-bid-management"></i>
            <h3>Bid Management</h3>
            <p>Place and track bids on listed contracts.</p>
          </div>
          <div className="feature">
            <i className="icon-vote-management"></i>
            <h3>Vote Management</h3>
            <p>Participate in voting for contract bids.</p>
          </div>
        </div>
      </section>

      {/* How to Use Section */}
      <section className="how-to-use-section">
        <h2>How to Use</h2>
        <p>Use the navigation links above to access different management sections:</p>
      </section>

      <Routes>
        <Route path="contract-management" element={
          <ContractManagement 
            marketplaceInstance={marketplaceInstance} 
            realEstateInstance={realEstateInstance}
            contractOwner={contractOwner}
          />
        } />
        <Route path="bid-management" element={
          <BidManagement 
            marketplaceInstance={marketplaceInstance} 
            realEstateInstance={realEstateInstance}
            contractOwner={contractOwner}
          />
        } />
        <Route path="vote-management" element={
          <VoteManagement 
            marketplaceInstance={marketplaceInstance} 
            realEstateInstance={realEstateInstance}
            contractOwner={contractOwner}
          />
        } />
      </Routes>
    </div>
  );
};

export default MarketPlacePage;



