import React, { useState, useEffect } from 'react';
import Web3 from 'web3'; // Import Web3
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const AddProposal = () => {
  const [proposalType, setProposalType] = useState('0'); // Assuming default type is Regular (0)
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [amount, setAmount] = useState('');
  const [recipient, setRecipient] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [proposalId, setProposalId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      try {
        const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
        setContractInstances(instances); // Set contract instances
      } catch (error) {
        console.error('Error initializing Web3:', error);
        setErrorMessage('Error initializing Web3. Please check the console for more details.');
      }
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
        setErrorMessage('Error fetching accounts. Please check the console for more details.');
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleAddProposal = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const proposalTypeInt = parseInt(proposalType, 10);
      if (isNaN(proposalTypeInt) || (proposalTypeInt !== 0 && proposalTypeInt !== 1)) {
        throw new Error('Invalid proposal type. It should be 0 (Regular) or 1 (Payment).');
      }

      const durationInSeconds = parseInt(duration, 10);
      if (isNaN(durationInSeconds)) {
        throw new Error('Invalid duration. Please provide a valid number.');
      }

      const amountValue = proposalTypeInt === 1 ? parseInt(amount, 10) : 0;
      const recipientAddress = proposalTypeInt === 1 ? recipient : '0x0000000000000000000000000000000000000000';

      if (proposalTypeInt === 1 && (!Web3.utils.isAddress(recipientAddress) || isNaN(amountValue))) {
        throw new Error('For payment proposals, amount and recipient must be valid.');
      }

      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      const result = await contractInstances.realEstateInstance.methods.addProposal(
        proposalTypeInt,
        description,
        durationInSeconds,
        amountValue,
        recipientAddress
      ).send({ from: account });

      setProposalId(result.events.ProposalAdded.returnValues.proposalId);
      setSuccessMessage(`Proposal added successfully! Proposal ID: ${result.events.ProposalAdded.returnValues.proposalId}`);
    } catch (error) {
      console.error(`Error adding proposal: ${error.message}`);
      setErrorMessage(`Error adding proposal: ${error.message}`);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Add Proposal</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleAddProposal}>
          <div>
            <label>
              Proposal Type:
              <select
                value={proposalType}
                onChange={(e) => setProposalType(e.target.value)}
              >
                <option value="0">Regular</option>
                <option value="1">Payment</option>
              </select>
            </label>
          </div>
          <div>
            <label>
              Description:
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Duration (seconds):
              <input
                type="number"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
              />
            </label>
          </div>
          {proposalType === '1' && (
            <>
              <div>
                <label>
                  Amount:
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  Recipient Address:
                  <input
                    type="text"
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                    required
                  />
                </label>
              </div>
            </>
          )}
          <div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Add Proposal'}
            </button>
          </div>
          {proposalId && <p>Proposal ID: {proposalId}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default AddProposal;
