// Footer.js
import React from 'react';
import './Footer.css'; // Optional: Create a CSS file for styling

const Footer = () => {
  return (
    <footer className="contact-section">
      <h2>Contact Us</h2>
      <p>We would love to hear from you! If you have any questions, feedback, or inquiries, please feel free to reach out to us at <a href="mailto:Info@Anttokenizations.com">Info@Anttokenizations.com</a> or <a href="tel:+16399991694">+1 639 999 1694</a>. You can also follow us to stay updated on our latest news and developments.</p>
    </footer>
  );
};

export default Footer;
