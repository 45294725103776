import React from 'react';
import styled from 'styled-components';
import Section from './Section';
import AddProposal from './AddProposal';
import CheckProposalApproval from './CheckProposalApproval';
import VoteComponent from './VoteComponent';
import FinalizeProposal from './FinalizeProposal';

const ProposalManagementGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const ProposalAction = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ManageProposals = ({ realEstateInstance, contractOwner }) => {
  return (
    <Section title="Manage Proposals">
      <ProposalManagementGrid>
        <ProposalAction>
          <AddProposal contractInstance={realEstateInstance} contractOwner={contractOwner} />
        </ProposalAction>
        <ProposalAction>
          <CheckProposalApproval contractInstance={realEstateInstance} />
        </ProposalAction>
        <ProposalAction>
          <VoteComponent contractInstance={realEstateInstance} />
        </ProposalAction>
        <ProposalAction>
          <FinalizeProposal contractInstance={realEstateInstance} />
        </ProposalAction>
      </ProposalManagementGrid>
    </Section>
  );
};

export default ManageProposals;

