import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const SetApprovalForAll = () => {
  const [operator, setOperator] = useState('');
  const [approved, setApproved] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleSetApprovalForAll = async (e) => {
    e.preventDefault();

    if (!operator) {
      setErrorMessage('Operator address is required.');
      return;
    }

    setIsSetting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Ensure contractInstance and account are available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      await contractInstances.realEstateInstance.methods.setApprovalForAll(operator, approved).send({ from: account });

      setSuccessMessage(`Approval ${approved ? 'granted' : 'revoked'} successfully!`);
    } catch (error) {
      console.error(`Error setting approval: ${error.message}`);
      setErrorMessage(`Error setting approval: ${error.message}`);
    } finally {
      setIsSetting(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Set Approval For All</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleSetApprovalForAll}>
          <div>
            <label>
              Operator Address:
              <input
                type="text"
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Approved:
              <input
                type="checkbox"
                checked={approved}
                onChange={(e) => setApproved(e.target.checked)}
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isSetting}>
              {isSetting ? 'Setting...' : `Set Approval For ${approved ? 'All' : 'None'}`}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default SetApprovalForAll;
