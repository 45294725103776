import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { doc, onSnapshot, setDoc, deleteDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { firestore } from './firebase';

const AiRequest = ({ tokenId, onResultsReady }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestInitiated, setRequestInitiated] = useState(false);

  const handleFirestoreError = useCallback((error) => {
    console.error("Firestore error:", error);
    setError("An error occurred. Please try again.");
    setLoading(false);
    setRequestInitiated(false);
  }, []);

  const setupFirestoreListener = useCallback(() => {
    if (!tokenId) return;

    const docRef = doc(firestore, 'uploads', tokenId);
    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.processed) {
            setResults(data);
            setLoading(false);
            setError(null);
            setRequestInitiated(false);
            console.log('AI analysis completed for tokenId:', tokenId, 'Result:', data);
            if (onResultsReady) onResultsReady(data);  // Call onResultsReady with the data
          } else if (data.status === 'processing') {
            setLoading(true);
            setError(null);
          }
        }
      },
      handleFirestoreError
    );

    return unsubscribe;
  }, [tokenId, handleFirestoreError, onResultsReady]);  // Add onResultsReady to dependencies

  useEffect(() => {
    const unsubscribe = setupFirestoreListener();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [setupFirestoreListener]);

  const handleAiRequest = useCallback(async () => {
    setLoading(true);
    setError(null);
    setRequestInitiated(true);
    setResults(null);

    const docRef = doc(firestore, 'aiRequests', tokenId);

    try {
      // Check if the document exists
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // If it exists, delete it
        await deleteDoc(docRef);
        console.log('Previous AI request document deleted');

        // Add a 2-second delay
        await new Promise(resolve => setTimeout(resolve, 2000));
      }

      // Create a new AI request document
      await setDoc(docRef, {
        tokenId,
        requestedAt: serverTimestamp(), // Use server timestamp
        status: 'pending'
      });

      console.log('New AI request document created successfully');
      setupFirestoreListener(); // Set up listener after initiating request
    } catch (error) {
      console.error('Error in handleAiRequest:', error);
      handleFirestoreError(error);
    } finally {
      setLoading(false);
    }
  }, [tokenId, handleFirestoreError, setupFirestoreListener]);

  const AnalysisButton = useMemo(() => (
    <Button
      onClick={handleAiRequest}
      disabled={loading}
      variant="contained"
      color={error ? "secondary" : "primary"}
      aria-label={error ? "Retry AI Analysis" : "Request AI Analysis"}
    >
      {error ? "Retry AI Analysis" : "Request AI Analysis"}
    </Button>
  ), [handleAiRequest, loading, error]);

  if (!tokenId) {
    return <Typography color="error">Error: No token ID provided</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      {AnalysisButton}

      {loading && (
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <CircularProgress size={24} sx={{ mr: 1 }} />
          <Typography>Processing...</Typography>
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {results && results.processed && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Results:</Typography>
          <Typography>Total Apple Count: {results.totalAppleCount}</Typography>
          <Link to={`/visualization/${tokenId}`} style={{ textDecoration: 'none' }}>
            <Button color="primary" variant="contained" sx={{ mt: 2 }}>
              View Visualizations
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default AiRequest;

