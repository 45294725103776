import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';

const ActiveContracts = () => {
  const [contractInstances, setContractInstances] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeContracts, setActiveContracts] = useState([]);
  const [account, setAccount] = useState('');

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const fetchActiveContracts = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      if (!contractInstances.marketplaceInstance) {
        throw new Error('Marketplace contract instance is not available.');
      }

      const activeIds = await contractInstances.marketplaceInstance.methods.getActiveContractIds().call();
      setActiveContracts(activeIds);
    } catch (error) {
      setErrorMessage(`Error fetching active contracts: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* <h3></h3> */}
      {isLoading && <p>Loading...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <button onClick={fetchActiveContracts} disabled={isLoading}>Fetch Active Contracts</button>
      {activeContracts.length > 0 && (
        <ul>
          {activeContracts.map((contractId) => (
            <li key={contractId}>Contract ID: {contractId}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ActiveContracts;
