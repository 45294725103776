import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';

const ContractsByRealEstate = () => {
  const [account, setAccount] = useState('');
  const [contractInstances, setContractInstances] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [realEstateAddressToFetch, setRealEstateAddressToFetch] = useState('');
  const [contractsByRealEstate, setContractsByRealEstate] = useState([]);

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleFetchContractsByRealEstate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setContractsByRealEstate([]);

    try {
      if (!contractInstances.marketplaceInstance) {
        throw new Error('Marketplace contract instance is not available.');
      }

      const contractIds = await contractInstances.marketplaceInstance.methods.getContractIdsByRealEstateContract(realEstateAddressToFetch).call();
      setContractsByRealEstate(contractIds);
    } catch (error) {
      setErrorMessage(`Error fetching contracts: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
       {/* <h3></h3> */}
      {isLoading && <p>Loading...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleFetchContractsByRealEstate}>
        <input
          type="text"
          placeholder="Real Estate Contract Address"
          value={realEstateAddressToFetch}
          onChange={(e) => setRealEstateAddressToFetch(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>Fetch Contracts</button>
      </form>
      {contractsByRealEstate.length > 0 && (
        <ul>
          {contractsByRealEstate.map((contractId) => (
            <li key={contractId}>Contract ID: {contractId}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ContractsByRealEstate;