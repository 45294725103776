import React from 'react';
import Section from './Section';
import ListContract from './ListContract';
import FinalizeContract from './FinalizeContract';
import ViewContractDetails from './ViewContractDetails';
import ActiveContracts from './ActiveContracts';
import ContractsByRealEstate from './ContractsByRealEstate';
import './ContractManagement.css';

const ContractManagement = () => {
  return (
    <Section title="Contract Management">
      <div className="contract-management-container">
        <div className="contract-management-scroll">
          <div className="contract-management-grid">
            <div className="contract-management-card">
              <h3>List Contracts</h3>
              <ListContract />
            </div>
            <div className="contract-management-card">
              <h3>Finalize Contract</h3>
              <FinalizeContract />
            </div>
            <div className="contract-management-card">
              <h3>View Contract Details</h3>
              <ViewContractDetails />
            </div>
            <div className="contract-management-card">
              <h3>Active Contracts</h3>
              <ActiveContracts />
            </div>
            <div className="contract-management-card">
              <h3>Contracts by Real Estate</h3>
              <ContractsByRealEstate />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ContractManagement;


