import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const CheckProposalApproval = () => {
  const [proposalId, setProposalId] = useState('');
  const [isApproved, setIsApproved] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractInstance, setContractInstance] = useState(null); // State to hold contract instance
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstance(instances.realEstateInstance); // Set contract instance
    };

    initWeb3();
  }, []);

  const handleCheckApproval = async (e) => {
    e.preventDefault();

    if (!proposalId) {
      setErrorMessage('Proposal ID is required.');
      return;
    }

    setIsChecking(true);
    setErrorMessage('');
    setIsApproved(null);

    try {
      if (!contractInstance) {
        throw new Error('Contract instance is not available.');
      }

      const result = await contractInstance.methods.isProposalApproved(parseInt(proposalId)).call();
      setIsApproved(result);
    } catch (error) {
      console.error(`Error checking proposal approval: ${error.message}`);
      setErrorMessage(`Error checking proposal approval: ${error.message}`);
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div>
      <h2>Check Proposal Approval</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleCheckApproval}>
          <div>
            <label>
              Proposal ID:
              <input
                type="number"
                value={proposalId}
                onChange={(e) => setProposalId(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isChecking}>
              {isChecking ? 'Checking...' : 'Check Approval'}
            </button>
          </div>
          {isApproved !== null && (
            <p>
              Proposal {isApproved ? 'is approved' : 'is not approved'}
            </p>
          )}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default CheckProposalApproval;
