import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const OwnerOf = () => {
  const [tokenId, setTokenId] = useState('');
  const [owner, setOwner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();
  }, []);

  const handleCheckOwner = async (e) => {
    e.preventDefault();

    if (!tokenId) {
      setErrorMessage('Token ID is required.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      // Ensure contractInstance is available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      const result = await contractInstances.realEstateInstance.methods.ownerOf(tokenId).call();
      setOwner(result);
    } catch (error) {
      console.error(`Error fetching owner: ${error.message}`);
      setErrorMessage(`Error fetching owner: ${error.message}`);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Check Token Owner</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleCheckOwner}>
          <div>
            <label>
              Token ID:
              <input
                type="text"
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Check Owner'}
            </button>
          </div>
          {owner && <p>Owner: {owner}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default OwnerOf;
