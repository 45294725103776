import React from 'react';
import Section from './Section';
import InitializeContract from './InitializeContract';
import IpfsComponent from './IpfsComponent';

const ContractInitialization = ({ realEstateInstance, contractOwner }) => {
  return (
    <Section title="Contract Initialization">
      <InitializeContract contractInstance={realEstateInstance} contractOwner={contractOwner} />
            <IpfsComponent contractInstance={realEstateInstance} contractOwner={contractOwner} />
    </Section>
  );
};

export default ContractInitialization;
