import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';
import axios from 'axios'; // Make sure to install axios: npm install axios

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    twitterHandle: '',
    walletAddress: '',
    location: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [verificationDialog, setVerificationDialog] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [actualVerificationCode, setActualVerificationCode] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    const disposableDomains = ['yopmail.com', 'tempmail.com', 'guerrillamail.com'];
    const domain = email.split('@')[1];
    return !disposableDomains.includes(domain);
  };

  const validateEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = 'First name is required';
    if (!formData.lastName) tempErrors.lastName = 'Last name is required';
    if (!formData.email) tempErrors.email = 'Email is required';
    if (!validateEmail(formData.email)) tempErrors.email = 'Invalid email address';
    if (!formData.twitterHandle) tempErrors.twitterHandle = 'Twitter handle is required';
    if (!formData.twitterHandle.startsWith('@')) tempErrors.twitterHandle = 'Twitter handle must start with @';
    if (!formData.walletAddress) tempErrors.walletAddress = 'Wallet address is required';
    if (!validateEthereumAddress(formData.walletAddress)) tempErrors.walletAddress = 'Invalid Ethereum wallet address';
    if (!formData.location) tempErrors.location = 'Location is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const sendVerificationEmail = async () => {
    try {
      const generatedCode = Math.floor(100000 + Math.random() * 900000).toString();
      setActualVerificationCode(generatedCode);

      // Use the deployed URL
      const apiUrl = 'https://anttokenization.uc.r.appspot.com';

      const response = await axios.post(`${apiUrl}/api/send-verification`, {
        to: formData.email,
        verificationCode: generatedCode
      });

      console.log('Server response:', response.data); // Add this line for debugging

      if (response.data.success) {
        setVerificationDialog(true);
      } else {
        throw new Error(response.data.message || 'Failed to send verification email');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setSnackbar({
        open: true,
        message: 'Failed to send verification email. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      // Check if email already exists
      const emailQuery = query(collection(firestore, 'users'), where('email', '==', formData.email));
      const emailQuerySnapshot = await getDocs(emailQuery);
      if (!emailQuerySnapshot.empty) {
        setErrors({ ...errors, email: 'This email is already registered' });
        setIsLoading(false);
        return;
      }

      // Check if wallet address already exists
      const walletQuery = query(collection(firestore, 'users'), where('walletAddress', '==', formData.walletAddress));
      const walletQuerySnapshot = await getDocs(walletQuery);
      if (!walletQuerySnapshot.empty) {
        setErrors({ ...errors, walletAddress: 'This wallet address is already registered' });
        setIsLoading(false);
        return;
      }

      // Send verification email
      await sendVerificationEmail();
    } catch (error) {
      console.error('Error during registration:', error);
      setSnackbar({
        open: true,
        message: 'Registration failed. Please try again.',
        severity: 'error',
      });
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async () => {
    if (verificationCode === actualVerificationCode) {
      try {
        // Add user to Firestore
        await addDoc(collection(firestore, 'users'), {
          ...formData,
          createdAt: new Date(),
        });

        setSnackbar({
          open: true,
          message: 'Registration successful!',
          severity: 'success',
        });
        setVerificationDialog(false);
        // Clear form fields
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          twitterHandle: '',
          walletAddress: '',
          location: '',
        });
        setErrors({});
      } catch (error) {
        console.error('Error adding user to Firestore:', error);
        setSnackbar({
          open: true,
          message: 'Failed to complete registration. Please try again.',
          severity: 'error',
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: 'Invalid verification code. Please try again.',
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="given-name"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="twitterHandle"
            label="Twitter Handle"
            name="twitterHandle"
            autoComplete="username"
            value={formData.twitterHandle}
            onChange={handleChange}
            error={!!errors.twitterHandle}
            helperText={errors.twitterHandle}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="walletAddress"
            label="Ethereum Wallet Address"
            name="walletAddress"
            value={formData.walletAddress}
            onChange={handleChange}
            error={!!errors.walletAddress}
            helperText={errors.walletAddress}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="location"
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            error={!!errors.location}
            helperText={errors.location}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? 'Registering...' : 'Register'}
          </Button>
        </Box>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={verificationDialog} onClose={() => setVerificationDialog(false)}>
        <DialogTitle>Email Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the verification code sent to your email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="verificationCode"
            label="Verification Code"
            type="text"
            fullWidth
            variant="standard"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVerificationDialog(false)}>Cancel</Button>
          <Button onClick={handleVerificationSubmit}>Verify</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RegistrationPage;
