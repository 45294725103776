import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const FinalizeProposal = () => {
  const [proposalId, setProposalId] = useState('');
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleFinalize = async (e) => {
    e.preventDefault();

    if (!proposalId) {
      setErrorMessage('Proposal ID is required.');
      return;
    }

    setIsFinalizing(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      await contractInstances.realEstateInstance.methods.finalizeProposal(parseInt(proposalId)).send({ from: account });

      setSuccessMessage('Proposal finalized successfully!');
    } catch (error) {
      console.error(`Error finalizing proposal: ${error.message}`);
      setErrorMessage(`Error finalizing proposal: ${error.message}`);
    } finally {
      setIsFinalizing(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Finalize Proposal</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleFinalize}>
          <div>
            <label>
              Proposal ID:
              <input
                type="number"
                value={proposalId}
                onChange={(e) => setProposalId(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isFinalizing}>
              {isFinalizing ? 'Finalizing...' : 'Finalize Proposal'}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default FinalizeProposal;
