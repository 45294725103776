import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const StartNewSeason = () => {
  const [seasonFee, setSeasonFee] = useState('');
  const [isStarting, setIsStarting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleStartSeason = async (e) => {
    e.preventDefault();

    if (!seasonFee) {
      setErrorMessage('Season fee is required.');
      return;
    }

    setIsStarting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Ensure contractInstance and account are available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      await contractInstances.realEstateInstance.methods.startNewSeason(seasonFee).send({ from: account });

      setSuccessMessage('New season started successfully!');
    } catch (error) {
      console.error(`Error starting new season: ${error.message}`);
      setErrorMessage(`Error starting new season: ${error.message}`);
    } finally {
      setIsStarting(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Start New Season</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleStartSeason}>
          <div>
            <label>
              Season Fee:
              <input
                type="number"
                value={seasonFee}
                onChange={(e) => setSeasonFee(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isStarting}>
              {isStarting ? 'Starting...' : 'Start Season'}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default StartNewSeason;
