import React from 'react';
import styled from 'styled-components';
import PaySeasonFee from './PaySeasonFee';

const SeasonFeeContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
`;

const SeasonFeeManagement = ({ realEstateInstance }) => {
  return (
    <SeasonFeeContainer>
      <Title>Season Fee Management</Title>
      <PaySeasonFee contractInstance={realEstateInstance} />
    </SeasonFeeContainer>
  );
};

export default SeasonFeeManagement;

