import React from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import './TokenizationPage.css'; // Import the entire CSS file
import SeasonManagement from './SeasonManagement';
import PlotManagement from './PlotManagement';
import ContractInitialization from './ContractInitialization';


const TokenizationPage = ({ realEstateInstance, contractOwner }) => {
  const navigate = useNavigate();

  // Debug logs
  console.log("TokenizationPage - realEstateInstance:", realEstateInstance);
  console.log("TokenizationPage - contractOwner:", contractOwner);

  return (
    <div className="admin-page">
      <h1 className="admin-page-title">Tokenization Management</h1>

      {/* Updated Navigation Bar */}
      <nav className="admin-page-nav">
        <ul>
          <li><Link to="/tokenization/contract-initialization" className="nav-link">Contract Initialization</Link></li>
          <li><Link to="/tokenization/plot-management" className="nav-link">Plot Management</Link></li>
          <li><Link to="/tokenization/season-management" className="nav-link">Season Management</Link></li>
          <li><button onClick={() => navigate('/tokenization')} className="nav-link back-button">Back to Main Page</button></li>
        </ul>
      </nav>

      {/* Introduction Section */}
      <section className="intro-section">
        <h2>Introduction</h2>
        <p>Welcome to the NFT Holder Management page. Here you can manage your NFTs, proposals, and season fees efficiently.</p>
        <p>We are transforming the landscape of real estate and farmland management by integrating cutting-edge technologies. Our platform leverages blockchain, AI, drone technology, and smart contracts to create a seamless, transparent, and efficient ecosystem for land ownership and investment.</p>
        <p>Select a section from the navigation links above to get started.</p>
      </section>





      <Routes>
        <Route path="contract-initialization" element={<ContractInitialization realEstateInstance={realEstateInstance} contractOwner={contractOwner} />} />
        <Route path="plot-management" element={<PlotManagement realEstateInstance={realEstateInstance} contractOwner={contractOwner} />} />
        <Route path="season-management" element={<SeasonManagement realEstateInstance={realEstateInstance} />} />
      </Routes>
    </div>
  );
};

export default TokenizationPage;

