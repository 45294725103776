import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import MarketPlaceDetails from './Marketplace.json'; 
import IERC20Details from './IERC20.json'; 
import IERC721Details from './IERC721.json'; 
import INFTWithIPNSDetails from './INFTWithIPNS.json'; 
import RealEstateTokenizationDetails from './RealEstateTokenization.json'; 

let web3;
let marketplaceInstance;
let realEstateInstance;
let erc20Instances = {};
let erc721Instance;
let nftWithIPNSInstance;

// Hardcoded network ID
const networkId = 1440002; // Replace with your actual network ID

// Initialize web3 instance and contracts
export async function initializeWeb3(setIsLoading) {
    setIsLoading(true);

    try {
        const provider = await detectEthereumProvider();
        
        if (provider) {
            web3 = new Web3(provider);
            console.log("Web3 initialized successfully with provider.");
        } else {
            // Fallback to direct RPC endpoint if no provider is found
            web3 = new Web3('https://rpc-evm-sidechain.xrpl.org');
            console.log("Web3 initialized successfully with direct endpoint.");
        }

        console.log(`Using hardcoded Network ID: ${networkId}`);

        // Initialize MarketPlace contract instance
        marketplaceInstance = createContractInstance(MarketPlaceDetails.abi, MarketPlaceDetails.networks[networkId]?.address, 'MarketPlace');
        if (!marketplaceInstance) {
            console.error('MarketPlace contract not deployed on the specified network.');
        }

        // Initialize RealEstateTokenization contract instance
        realEstateInstance = createContractInstance(RealEstateTokenizationDetails.abi, RealEstateTokenizationDetails.networks[networkId]?.address, 'RealEstateTokenization');
        if (!realEstateInstance) {
            console.error('RealEstateTokenization contract not deployed on the specified network.');
        }

        // Initialize additional contract instances
        erc721Instance = createContractInstance(IERC721Details.abi, IERC721Details.networks[networkId]?.address, 'ERC721');
        nftWithIPNSInstance = createContractInstance(INFTWithIPNSDetails.abi, INFTWithIPNSDetails.networkId?.address, 'INFTWithIPNS');

        return { marketplaceInstance, realEstateInstance, erc20Instances, erc721Instance, nftWithIPNSInstance };

    } catch (error) {
        console.error("Error initializing Web3:", error);
    } finally {
        setIsLoading(false);
    }
}

// Helper function to create contract instances
function createContractInstance(abi, contractAddress, description) {
    try {
        if (!contractAddress) {
            console.error(`${description} contract not deployed on the specified network.`);
            return null;
        }
        const contractInstance = new web3.eth.Contract(abi, contractAddress);
        console.log(`${description} contract instance created for address: ${contractAddress}`);
        return contractInstance;
    } catch (error) {
        console.error(`Error creating ${description} contract instance for ${contractAddress}:`, error);
        return null;
    }
}

// Function to get an instance of the IERC20 contract
export function getERC20ContractInstance(tokenAddress) {
    if (!erc20Instances[tokenAddress]) {
        const instance = createContractInstance(IERC20Details.abi, tokenAddress, 'ERC20');
        if (instance) {
            erc20Instances[tokenAddress] = instance;
        }
    }
    return erc20Instances[tokenAddress] || null;
}

// Function to get an instance of the IERC721 contract
export function getERC721ContractInstance(nftContractAddress) {
    return createContractInstance(IERC721Details.abi, nftContractAddress, 'ERC721');
}

// Function to get an instance of the INFTWithIPNS contract
export function getNFTWithIPNSContractInstance(nftContractAddress) {
    return createContractInstance(INFTWithIPNSDetails.abi, nftContractAddress, 'INFTWithIPNS');
}

// Export contract instances
export { web3, marketplaceInstance, realEstateInstance, erc721Instance, nftWithIPNSInstance };

export async function isWeb3Enabled() {
    const provider = await detectEthereumProvider();
    
    if (provider) {
        try {
            // Request account access
            await provider.request({ method: 'eth_requestAccounts' });
            return true;
        } catch (error) {
            console.error('User denied account access', error);
            return false;
        }
    } else {
        console.log('Please install MetaMask!');
        return false;
    }
}
