import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const MintBatchPlots = () => {
  const [batchSize, setBatchSize] = useState('');
  const [recipient, setRecipient] = useState('');
  const [account, setAccount] = useState('');
  const [isMinting, setIsMinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleMintBatch = async (e) => {
    e.preventDefault();

    if (!batchSize || !recipient) {
      setErrorMessage('Both batch size and recipient address are required.');
      return;
    }

    setIsMinting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Ensure contractInstance and account are available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      await contractInstances.realEstateInstance.methods.mintBatch(
        parseInt(batchSize),
        recipient
      ).send({ from: account });

      setSuccessMessage('Plots minted successfully!');
    } catch (error) {
      console.error(`Error minting plots: ${error.message}`);
      setErrorMessage(`Error minting plots: ${error.message}`);
    } finally {
      setIsMinting(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Mint Batch Plots</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleMintBatch}>
          <div>
            <label>
              Batch Size:
              <input
                type="number"
                value={batchSize}
                onChange={(e) => setBatchSize(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Recipient Address:
              <input
                type="text"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isMinting}>
              {isMinting ? 'Minting...' : 'Mint Batch Plots'}
            </button>
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default MintBatchPlots;
