import React from 'react';
import Section from './Section';
import MintBatchPlots from './MintBatchPlots';
import './PlotManagement.css';

const PlotManagement = ({ realEstateInstance }) => {
  return (
    <Section title="Plot Management">
      <div className="plot-management-container">
        <div className="plot-management-grid">
          <div className="plot-management-card">
            <h3>Mint Batch Plots</h3>
            <MintBatchPlots contractInstance={realEstateInstance} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PlotManagement;