import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';

const PaySeasonFee = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [seasonFee, setSeasonFee] = useState(null);
  const [account, setAccount] = useState(null);
  const [contractInstances, setContractInstances] = useState(null);
  const [ownedTokens, setOwnedTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(undefined); // Use undefined for no selection
  const [totalPlots, setTotalPlots] = useState(0);

  // Initialize Web3 and contract instances
  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      console.log('Web3 and contract instances:', instances);
      setContractInstances(instances);

      const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
          console.log('Please connect to MetaMask.');
          setAccount(null);
        } else if (accounts[0] !== account) {
          setAccount(accounts[0]);
        }
      };

      window.ethereum.request({ method: 'eth_accounts' })
        .then(handleAccountsChanged)
        .catch((err) => {
          console.error('Error fetching accounts:', err);
        });

      window.ethereum.on('accountsChanged', handleAccountsChanged);

      return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
    };

    initWeb3();
  }, [account]);

  // Fetch Season Fee
  useEffect(() => {
    const fetchSeasonFee = async () => {
      if (!contractInstances?.realEstateInstance) {
        setError('Contract instance is not available');
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        console.log('Calling seasonFee function...');
        const fee = await contractInstances.realEstateInstance.methods.seasonFee().call();
        console.log('Season fee:', fee);
        setSeasonFee(fee);
      } catch (err) {
        console.error(`Error fetching season fee: ${err.message}`);
        setError(`Error fetching season fee: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (contractInstances) {
      fetchSeasonFee();
    }
  }, [contractInstances]);

  // Fetch owned tokens and total plots
  useEffect(() => {
    const fetchTotalPlotsAndTokens = async () => {
      if (!contractInstances?.realEstateInstance || !account) {
        return;
      }
      try {
        const plots = await contractInstances.realEstateInstance.methods.totalPlots().call();
        setTotalPlots(parseInt(plots));

        const tokens = [];
        for (let i = 0; i < plots; i++) {
          const owner = await contractInstances.realEstateInstance.methods.ownerOf(i).call();
          if (owner.toLowerCase() === account.toLowerCase()) {
            tokens.push(i);
          }
        }
        setOwnedTokens(tokens);
        if (tokens.length > 0) {
          setSelectedToken(tokens[0]); // Default to the first token owned
        }
      } catch (err) {
        console.error("Error fetching owned tokens or total plots:", err);
        setError("Failed to fetch owned tokens or total plots.");
      }
    };

    if (contractInstances && account) {
      fetchTotalPlotsAndTokens();
    }
  }, [contractInstances, account]);

  // Pay Season Fee
  const payFee = async (e) => {
    e.preventDefault();
    if (selectedToken === undefined) {
      setError("Please select a token");
      return;
    }
    if (!seasonFee) {
      setError("Season fee is not available");
      return;
    }
    setIsPaying(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const tx = await contractInstances.realEstateInstance.methods.paySeasonFee(selectedToken).send({
        from: account,
        value: seasonFee,
      });
      console.log('Transaction successful:', tx);
      setSuccessMessage('Season fee paid successfully!');
    } catch (err) {
      console.error("Error paying season fee:", err);
      setError(`Failed to pay the season fee: ${err.message}`);
    } finally {
      setIsPaying(false);
      setTimeout(() => {
        setError(null);
        setSuccessMessage(null);
      }, 5000);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Pay Season Fee</h2>
      {!account ? (
        <p>Please connect your wallet to continue.</p>
      ) : ownedTokens.length === 0 ? (
        <p>You don't own any tokens.</p>
      ) : (
        <form onSubmit={payFee}>
          <div>
            <label htmlFor="tokenSelect">Select Token:</label>
            <select
              id="tokenSelect"
              value={selectedToken === undefined ? '' : selectedToken} // Handle 0 as a valid token
              onChange={(e) => setSelectedToken(Number(e.target.value))}
            >
              <option value="" disabled>Select your token</option> {/* Placeholder */}
              {ownedTokens.map((tokenId) => (
                <option key={tokenId} value={tokenId}>
                  Token #{tokenId}
                </option>
              ))}
            </select>
          </div>
          {seasonFee !== null ? (
            <p>Season Fee: {seasonFee} Wei</p>
          ) : (
            <p>Season fee not available.</p>
          )}
          <button type="submit" disabled={isPaying || selectedToken === undefined || !seasonFee}>
            {isPaying ? 'Processing...' : 'Pay Season Fee'}
          </button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default PaySeasonFee;
