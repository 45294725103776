import React, { useState, useEffect } from 'react';
import Web3 from 'web3'; // Import Web3
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const VoteComponent = () => {
  const [proposalId, setProposalId] = useState('');
  const [support, setSupport] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Initialize Web3 and get contract instances
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleVote = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const id = parseInt(proposalId, 10);
      if (isNaN(id) || id < 0) {
        throw new Error('Invalid proposal ID');
      }

      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      // Convert support to a boolean
      const supportBool = support.toLowerCase() === 'true';

      const result = await contractInstances.realEstateInstance.methods.vote(id, supportBool).send({ from: account });

      setSuccessMessage('Vote submitted successfully!');
    } catch (error) {
      console.error(`Error voting on proposal: ${error.message}`);
      setErrorMessage(`Error voting on proposal: ${error.message}`);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Vote on Proposal</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleVote}>
          <div>
            <label>
              Proposal ID:
              <input
                type="number"
                value={proposalId}
                onChange={(e) => setProposalId(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Support:
              <select
                value={support}
                onChange={(e) => setSupport(e.target.value)}
                required
              >
                <option value="">Select</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </label>
          </div>
          <div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit Vote'}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default VoteComponent;
