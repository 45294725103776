import React from 'react';
import Section from './Section';
import PlaceBid from './PlaceBid';
import ViewBids from './ViewBids';
import LowestBid from './LowestBid';
import './BidManagement.css';

const BidManagement = () => {
  return (
    <Section title="Bid Management">
      <div className="bid-management-container">
        <div className="bid-management-scroll">
          <div className="bid-management-grid">
            <div className="bid-management-card">
              <h3>Place Bid</h3>
              <PlaceBid />
            </div>
            <div className="bid-management-card">
              <h3>View Bids</h3>
              <ViewBids />
            </div>
            <div className="bid-management-card">
              <h3>Lowest Bid</h3>
              <LowestBid />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default BidManagement;