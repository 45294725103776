import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const TransferFrom = () => {
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
        setFromAddress(accounts[0]); // Update the from address when the account changes
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleTransfer = async (e) => {
    e.preventDefault();

    if (!fromAddress || !toAddress || !tokenId) {
      setErrorMessage('All fields are required.');
      return;
    }

    setIsTransferring(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Ensure contractInstance and account are available
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      await contractInstances.realEstateInstance.methods.transferFrom(fromAddress, toAddress, tokenId).send({ from: account });

      setSuccessMessage('Token transferred successfully!');
    } catch (error) {
      console.error(`Error transferring token: ${error.message}`);
      setErrorMessage(`Error transferring token: ${error.message}`);
    } finally {
      setIsTransferring(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  return (
    <div>
      <h2>Transfer Token</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleTransfer}>
          <div>
            <label>
              From Address:
              <input
                type="text"
                value={fromAddress}
                onChange={(e) => setFromAddress(e.target.value)}
                required
                readOnly
              />
            </label>
          </div>
          <div>
            <label>
              To Address:
              <input
                type="text"
                value={toAddress}
                onChange={(e) => setToAddress(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Token ID:
              <input
                type="text"
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isTransferring}>
              {isTransferring ? 'Transferring...' : 'Transfer'}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default TransferFrom;
