// firebase.js
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5FohJN7PBjVRzzLil-iKJLt6k7FdutJY",
  authDomain: "anttokenization.firebaseapp.com",
  projectId: "anttokenization",
  storageBucket: "anttokenization.appspot.com",
  messagingSenderId: "938019262969",
  appId: "1:938019262969:web:7a9023092bb3b81e14ad28",
};

// Initialize Firebase
const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);

// Initialize Firebase services
const storage = getStorage(app);
const firestore = getFirestore(app);

export { app, storage, firestore };
