import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';

const ViewContractDetails = () => {
  const [contractIdToView, setContractIdToView] = useState('');
  const [contractDetails, setContractDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractInstances, setContractInstances] = useState({});
  const [account, setAccount] = useState('');

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleViewContractDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setContractDetails(null);

    try {
      if (!contractInstances.marketplaceInstance) {
        throw new Error('Marketplace contract instance is not available.');
      }

      const details = await contractInstances.marketplaceInstance.methods.getContractFullDetails(contractIdToView).call();
      setContractDetails(details);
    } catch (error) {
      setErrorMessage(`Error fetching contract details: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
       {/* <h3></h3> */}
      {isLoading && <p>Loading...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleViewContractDetails}>
        <input
          type="number"
          placeholder="Contract ID"
          value={contractIdToView}
          onChange={(e) => setContractIdToView(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>View Details</button>
      </form>
      {contractDetails && (
        <div>
          <h4>Contract Details:</h4>
          {/* ... (keep the existing contract details display) */}
        </div>
      )}
    </div>
  );
};

export default ViewContractDetails;
