import React, { useState } from 'react';
import axios from 'axios';

const IpfsComponent = () => {
  const [numNFTs, setNumNFTs] = useState(1);
  const [ipnsLink, setIpnsLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleTokenize = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://flask-app-938019262969.us-central1.run.app/tokenize', {
        numNFTs: numNFTs
      });
      setIpnsLink(response.data.general_ipns_link);
    } catch (err) {
      setError('Failed to tokenize. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Tokenize NFTs</h1>
      <input
        type="number"
        value={numNFTs}
        onChange={(e) => setNumNFTs(e.target.value)}
        min="1"
      />
      <button onClick={handleTokenize} disabled={loading}>
        {loading ? 'Tokenizing...' : 'Tokenize'}
      </button>
      {ipnsLink && (
        <div>
          <h2>IPNS Link</h2>
          <p>{ipnsLink}</p>
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default IpfsComponent;
