import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';

const ListContract = () => {
  const [account, setAccount] = useState('');
  const [contractInstances, setContractInstances] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [realEstateContract, setRealEstateContract] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [maxBudget, setMaxBudget] = useState('');
  const [biddingDuration, setBiddingDuration] = useState('');
  const [votingDuration, setVotingDuration] = useState('');

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleListContract = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      if (!contractInstances.marketplaceInstance) {
        throw new Error('Marketplace contract instance is not available.');
      }

      // Your contract listing logic here
      // For example:
      // await contractInstances.marketplaceInstance.methods.listContract(...).send({ from: account });
      setSuccessMessage('Contract listed successfully!');
    } catch (error) {
      setErrorMessage(`Error listing contract: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
       {/* <h3></h3> */}
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleListContract}>
          <input type="text" placeholder="Real Estate Contract Address" value={realEstateContract} onChange={(e) => setRealEstateContract(e.target.value)} required />
          <input type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
          <input type="number" placeholder="Duration (in days)" value={duration} onChange={(e) => setDuration(e.target.value)} required />
          <input type="number" placeholder="Max Budget (in ETH)" value={maxBudget} onChange={(e) => setMaxBudget(e.target.value)} required />
          <input type="number" placeholder="Bidding Duration (in days)" value={biddingDuration} onChange={(e) => setBiddingDuration(e.target.value)} required />
          <input type="number" placeholder="Voting Duration (in days)" value={votingDuration} onChange={(e) => setVotingDuration(e.target.value)} required />
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Listing...' : 'List Contract'}
          </button>
        </form>
      )}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </div>
  );
};

export default ListContract;
