import React from 'react';
import Section from './Section';
import CastVote from './CastVote';
import ViewVotes from './ViewVotes';
import VotingStatus from './VotingStatus';
import './VoteManagement.css';

const VoteManagement = () => {
  return (
    <Section title="Vote Management">
      <div className="vote-management-container">
        <div className="vote-management-scroll">
          <div className="vote-management-grid">
            <div className="vote-management-card">
              <h3>Cast Vote</h3>
              <CastVote />
            </div>
            <div className="vote-management-card">
              <h3>View Votes</h3>
              <ViewVotes />
            </div>
            <div className="vote-management-card">
              <h3>Voting Status</h3>
              <VotingStatus />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default VoteManagement;
