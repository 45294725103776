import React from 'react';
import Section from './Section';
import StartNewSeason from './StartNewSeason';
import SetSeasonFee from './SetSeasonFee';
import './SeasonManagement.css';

const SeasonManagement = ({ realEstateInstance }) => {
  return (
    <Section title="Season Management">
      <div className="season-management-container">
        <div className="season-management-grid">
          <div className="season-management-card">
            <h3>Start New Season</h3>
            <StartNewSeason contractInstance={realEstateInstance} />
          </div>
          <div className="season-management-card">
            <h3>Set Season Fee</h3>
            <SetSeasonFee contractInstance={realEstateInstance} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default SeasonManagement;