import React from 'react';
import { useNavigate } from 'react-router-dom';
import droneVideo from './Dronevideo.mp4';
import beforeImage1 from './before-image-crop-health.jpg';
import afterImage1 from './after-image-crop-health.jpg';
import beforeImage2 from './before-image-yield.jpg';
import afterImage2 from './after-image-yield.jpg';
import './DroneAIPage.css';

const DroneAIPage = () => {
  const navigate = useNavigate();

  return (
    <div className="drone-ai-page">
      <header className="header">
        <h1>Drone & AI Integration</h1>
        <button onClick={() => navigate('/')} className="nav-link">Back to Main Page</button>
      </header>

      <main>
        <section className="hero">
          <div className="video-container">
            <video controls>
              <source src={droneVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="hero-content">
            <h2>Video Demonstration</h2>
            <p>Explore how our cutting-edge drone technology is revolutionizing data collection and location mapping in agriculture.</p>
          </div>
        </section>

        <section className="features">
          <h2>AI Features</h2>
          <p>Our AI capabilities, developed in partnership with <a href="https://cropmind.ca/">Cropmind Inc.</a>, enhance agricultural productivity and efficiency.</p>
          <div className="feature-grid">
            <div className="feature-card">
              <h3>Tree and Fruit Counting</h3>
              <p>Accurate counting for yield estimation and inventory management.</p>
            </div>
            <div className="feature-card">
              <h3>Growth Stage Monitoring</h3>
              <p>Track plant development to ensure optimal growth conditions.</p>
            </div>
            <div className="feature-card">
              <h3>Pest Detection</h3>
              <p>Early detection of pests for timely intervention and treatment.</p>
            </div>
            <div className="feature-card">
              <h3>IPNS Link Updates</h3>
              <p>Continuous updates of plot information for detailed insights.</p>
            </div>
          </div>
        </section>

        <section className="cloud-integration">
          <h2>Cloud Deployment and Integration</h2>
          <p>Our AI is deployed on the cloud, ensuring scalability, reliability, and real-time data processing.</p>
          <div className="integration-grid">
            <div className="integration-item">
              <h3>AI on the Cloud</h3>
              <p>Real-time insights and updates from drone footage and other data.</p>
            </div>
            <div className="integration-item">
              <h3>Distributed Database (IPNS)</h3>
              <p>Secure, decentralized, and tamper-proof data storage.</p>
            </div>
            <div className="integration-item">
              <h3>Blockchain Technology</h3>
              <p>Transparency and security in all transactions and data exchanges.</p>
            </div>
          </div>
        </section>

        <section className="benefits">
          <h2>Benefits</h2>
          <div className="benefits-grid">
            <div className="benefit-column">
              <h3>For Farmers</h3>
              <ul>
                <li>Informed Decision-Making</li>
                <li>Increased Productivity</li>
                <li>Cost Savings</li>
              </ul>
            </div>
            <div className="benefit-column">
              <h3>For Investors</h3>
              <ul>
                <li>Transparency</li>
                <li>Predictable Returns</li>
                <li>Enhanced Portfolio Management</li>
                <li>Increased Confidence</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="visual-examples">
          <h2>Visual Examples</h2>
          <p>See the impact of our AI analysis on farm plots through visual examples.</p>
          <div className="image-comparisons">
            <div className="image-comparison">
              <h3>Yield Estimation</h3>
              <div className="image-container">
                <img src={beforeImage1} alt="Before AI Yield Estimation" />
                <p>Before AI Analysis</p>
              </div>
              <div className="image-container">
                <img src={afterImage1} alt="After AI Yield Estimation" />
                <p>After AI Analysis</p>
              </div>
            </div>
            <div className="image-comparison">
              <h3>Yield Estimation</h3>
              <div className="image-container">
                <img src={beforeImage2} alt="Before AI Yield Estimation" />
                <p>Before AI Analysis</p>
              </div>
              <div className="image-container">
                <img src={afterImage2} alt="After AI Yield Estimation" />
                <p>After AI Analysis</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DroneAIPage;

