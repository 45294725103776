import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Container,
  Fade,
  Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: theme.shadows[8],
  },
}));

const StyledCardMedia = styled(CardMedia)({
  paddingTop: '75%', // 4:3 aspect ratio
  backgroundSize: 'contain',
});

const VisualizationPage = () => {
  const { tokenId } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      if (!tokenId) {
        setError('Invalid token ID');
        setLoading(false);
        return;
      }

      const storage = getStorage();
      const listRef = ref(storage, `visualizations/${tokenId}`);

      try {
        const res = await listAll(listRef);
        const imagePromises = res.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return {
            name: itemRef.name,
            url: url
          };
        });

        const imageResults = await Promise.all(imagePromises);
        setImages(imageResults);
      } catch (error) {
        console.error('Error fetching images:', error);
        setError('Failed to load the annotated images. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [tokenId]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {[...Array(6)].map((_, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Skeleton variant="rectangular" height={200} />
              <Skeleton variant="text" />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h5" color="error" gutterBottom>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h3" gutterBottom align="center">
        Annotated Images for NFT {tokenId}
      </Typography>
      {images.length === 0 ? (
        <Typography variant="h5" align="center">
          No annotated images available for this NFT.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {images.map((image, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Fade in={true} timeout={300 * (index + 1)}>
                <StyledCard>
                  <StyledCardMedia
                    image={image.url}
                    title={`Annotated image ${image.name}`}
                  />
                  <CardContent>
                    <Typography variant="subtitle1" component="h2">
                      {image.name}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Fade>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default VisualizationPage;