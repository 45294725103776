import React, { useState, useEffect } from 'react';
import './ConnectWalletPage.css';
import VisionImage from './e76fa0_527d38bfc07d4d6690601ef4d1b56bec.jpeg';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import QRCodeModal from "@walletconnect/qrcode-modal";
import QRCode from 'qrcode.react';

const ConnectWalletPage = () => {
    const [connectionStatus, setConnectionStatus] = useState('');
    const [provider, setProvider] = useState(null);
    const [walletConnectUri, setWalletConnectUri] = useState('');

    useEffect(() => {
        return () => {
            if (provider && provider.disconnect) {
                provider.disconnect();
            }
        };
    }, [provider]);

    const connectWallet = async () => {
        setConnectionStatus('Connecting...');
        console.log('Connect Wallet button clicked');

        try {
            let web3Provider;

            // Check if MetaMask is available
            if (typeof window.ethereum !== 'undefined') {
                console.log('MetaMask detected');
                try {
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    web3Provider = window.ethereum;
                    console.log('Connected to MetaMask');
                } catch (error) {
                    console.error("MetaMask connection failed:", error);
                    setConnectionStatus('MetaMask connection failed. Trying WalletConnect...');
                }
            } else {
                console.log('MetaMask not detected, proceeding with WalletConnect');
            }

            // If MetaMask is not available or failed, use WalletConnect
            if (!web3Provider) {
                console.log('Initializing WalletConnect');
                const walletConnectProvider = new WalletConnectProvider({
                    rpc: {
                        1440002: 'https://rpc-evm-sidechain.xrpl.org'
                    },
                    chainId: 1440002,
                    bridge: "https://bridge.walletconnect.org",
                    qrcodeModal: QRCodeModal,
                    qrcodeModalOptions: {
                        mobileLinks: [
                            "metamask",
                            "trust",
                            "rainbow",
                            "argent",
                            "imtoken",
                            "pillar"
                        ]
                    }
                });

                if (!walletConnectProvider.connector.uri) {
                    console.error("WalletConnect URI is not available");
                    setConnectionStatus('Failed to generate WalletConnect link. Please try again.');
                    return;
                }
                console.log("WalletConnect URI:", walletConnectProvider.connector.uri);
                setWalletConnectUri(walletConnectProvider.connector.uri);

                try {
                    console.log('Enabling WalletConnect...');
                    await walletConnectProvider.enable();
                    web3Provider = walletConnectProvider;
                    console.log('WalletConnect enabled successfully');

                    // Set up WalletConnect specific event listeners
                    walletConnectProvider.on("connect", (error, payload) => {
                        if (error) {
                            console.error("WalletConnect connect error:", error);
                            return;
                        }
                        console.log("WalletConnect connected:", payload);
                    });

                    walletConnectProvider.on("disconnect", (error, payload) => {
                        if (error) {
                            console.error("WalletConnect disconnect error:", error);
                            return;
                        }
                        console.log("WalletConnect disconnected:", payload);
                        setConnectionStatus('Disconnected');
                        setProvider(null);
                        setWalletConnectUri('');
                    });

                } catch (error) {
                    console.error("WalletConnect connection failed:", error);
                    setConnectionStatus('WalletConnect connection failed. Please try again.');
                    return;
                }
            }

            setProvider(web3Provider);
            const web3 = new Web3(web3Provider);
            console.log('Web3 instance created');

            const accounts = await web3.eth.getAccounts();
            console.log('Accounts:', accounts);

            if (accounts.length === 0) {
                throw new Error('No accounts found');
            }

            const chainId = await web3.eth.getChainId();
            console.log('Current chainId:', chainId);

            if (chainId !== 1440002) {
                console.log('Incorrect chain, attempting to switch...');
                try {
                    await web3Provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x15f902' }], // 1440002 in hex
                    });
                    console.log('Chain switched successfully');
                } catch (switchError) {
                    console.error('Error switching chain:', switchError);
                    // This error code indicates that the chain has not been added to the wallet
                    if (switchError.code === 4902) {
                        console.log('Chain not found, attempting to add...');
                        try {
                            await web3Provider.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: '0x15f902',
                                    chainName: 'XRPL EVM Sidechain',
                                    nativeCurrency: {
                                        name: 'XRP',
                                        symbol: 'XRP',
                                        decimals: 18
                                    },
                                    rpcUrls: ['https://rpc-evm-sidechain.xrpl.org'],
                                    blockExplorerUrls: ['https://evm-sidechain.xrpl.org']
                                }],
                            });
                            console.log('Chain added successfully');
                        } catch (addError) {
                            console.error('Error adding chain:', addError);
                            throw addError;
                        }
                    } else {
                        throw switchError;
                    }
                }
            }

            setConnectionStatus(`Connected with account: ${accounts[0]}`);
            console.log('Connection status updated');

            // Set up event listeners
            web3Provider.on("accountsChanged", (newAccounts) => {
                console.log('Accounts changed:', newAccounts);
                setConnectionStatus(`Connected with account: ${newAccounts[0]}`);
            });

        } catch (error) {
            console.error('Failed to connect wallet:', error);
            setConnectionStatus(`Failed to connect: ${error.message}`);
        }
    };

    return (
        <main className="homepage">
            <section className="hero1-section">
                <div className="hero1-content">
                    <div className="hero1-image">
                        <img src={VisionImage} alt="Drone Footage" />
                    </div>
                    <div className="hero1-text">
                        <h1>Connect Your Wallet to Get Started</h1>
                        <button className="cta-button" onClick={connectWallet}>Connect Wallet</button>
                        {connectionStatus && <p className="connection-status">{connectionStatus}</p>}
                    </div>
                </div>
            </section>

            {/* Instructions Section */}
            <section className="bubble-section container">
                <h2>How to Connect MetaMask to Ripple EVM Side Chain</h2>
                <ol>
                    <li>Install MetaMask from the <a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer">official website</a>.</li>
                    <li>Open MetaMask and create a new wallet or import an existing one.</li>
                    <li>Click on the network dropdown at the top and select "Custom RPC".</li>
                    <li>Enter the following details to connect to the Ripple EVM side chain:
                        <ul>
                            <li><strong>Network Name:</strong> XRPL EVM Sidechain</li>
                            <li><strong>New RPC URL:</strong> https://rpc-evm-sidechain.xrpl.org</li>
                            <li><strong>Chain ID:</strong> 1440002</li>
                            <li><strong>Currency Symbol:</strong> XRP</li>
                            <li><strong>Block Explorer URL:</strong> https://evm-sidechain.xrpl.org</li>
                        </ul>
                    </li>
                    <li>Click "Save" and select the Ripple EVM network from the network dropdown.</li>
                    <li>Your MetaMask wallet is now connected to the Ripple EVM side chain!</li>
                    <li>Make sure to have Testnet XRP before proceeding to interact with the contract</li>
                </ol>
            </section>

            {/* Operating with the Contract Section */}
            <section className="bubble-section container">
                <h2>Operating with the Contract</h2>
                <p>Once your wallet is connected, you can interact with our smart contracts on the Ripple EVM side chain. Here are some basic operations:</p>
                <ul>
                    <li><strong>View Contract:</strong> Use a block explorer to view the contract details.</li>
                    <li><strong>Send Transactions:</strong> Use MetaMask to send transactions to the contract.</li>
                    <li><strong>Check Balance:</strong> Use MetaMask to check your token balance.</li>
                    <li><strong>Interact with Functions:</strong> Use our platform's interface to call contract functions.</li>
                </ul>
            </section>

            {/* Company Information Section */}
            <section className="bubble-section container">
                <h2>About Our Company</h2>
                <p>We are revolutionizing farmland ownership and management with blockchain, smart contracts, distributed databases, artificial intelligence, cloud computing, and drone technology. Our platform leverages these cutting-edge technologies to create a seamless, transparent, and efficient ecosystem for land ownership and investment.</p>
                <p>Our vision is to build a transparent, efficient platform that makes land ownership and investment accessible to everyone. By empowering investors and landowners with fractional ownership and real-time insights, we aim to create a thriving ecosystem that drives economic growth and innovation.</p>
            </section>

            {/* Why Now Section */}
            <section className="why-now-section">
                <h2>Why Now?</h2>
                <div className="why-now-content">
                    <div className="why-now-point">
                        <h4>Digital Transformation</h4>
                        <p>Embrace the digital revolution in agriculture, where technology drives efficiency and profitability.</p>
                    </div>
                    <div class="why-now-point">
                        <h4>Data-Driven Decisions</h4>
                        <p>Utilize real-time data from drones and AI to make informed decisions, reducing risks and maximizing returns.</p>
                    </div>
                    <div class="why-now-point">
                        <h4>Scalable Investments</h4>
                        <p>Access scalable investment opportunities in farmland, with fractional ownership and diversified portfolios.</p>
                    </div>
                    <div class="why-now-point">
                        <h4>Environmental Impact</h4>
                        <p>Invest in sustainable practices that not only yield profits but also contribute to environmental conservation.</p>
                    </div>
                    <div class="why-now-point">
                        <h4>Market Demand</h4>
                        <p>Capitalize on the growing demand for innovative agricultural solutions and sustainable food production.</p>
                    </div>
                    <div class="why-now-point">
                        <h4>Future-Proofing</h4>
                        <p>Stay ahead of the curve by investing in technologies that future-proof agriculture and ensure long-term growth.</p>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ConnectWalletPage;

