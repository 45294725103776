import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';
import VisionImage from './anttokenization4.jpeg';
import DroneVideo from './Dronevideo.mp4'; // Import the video file
import { useMediaQuery } from 'react-responsive';
import Dronesectionimage from './Anttokenization1.jpg';
import Realworldbenefit from './Realworldbenefit.png';
import Tokenimagesection from './anttokenization3.jpg';

const HomePage = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
    return (
        <main className="homepage">
            {/* Hero Section */}
            <section className={`hero-section ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}`}>
  <div className="hero-content row">
    <div className="hero-image col-md-6">
      <img src={VisionImage} alt="Drone Footage" className="img-fluid" />
    </div>
    <div className="hero-text col-md-6">
      <h1 className="display-4">Revolutionizing Real Estate Ownership and Management with Blockchain, Smart Contracts, Distributed Database, Artificial Intelligence, Cloud Computing, and Drone Technology</h1>
      <Link to="/marketplace">
        <button className="btn btn-primary">Get Started</button>
      </Link>
    </div>
  </div>
</section>

{/* key-features-section */}
            <section className="key-features-section container">
  <h2 className="text-center">Welcome to ANT Tokenizations</h2>
  <p className="text-center">We are transforming the landscape of real estate and farmland management by integrating cutting-edge technologies. Our platform leverages blockchain, AI, drone technology, and smart contracts to create a seamless, transparent, and efficient ecosystem for land ownership and investment.</p>
  <div className="row">
    <div className="col-md-4 feature">
      <i className="icon-blockchain"></i>
      <h3>Blockchain-Powered Ownership</h3>
      <p>Secure and transparent ownership through blockchain technology.</p>
    </div>
    <div className="col-md-4 feature">
      <i className="icon-ipns"></i>
      <h3>IPNS Distributed Database</h3>
      <p>Access and manage decentralized property data with a secure IPNS distributed database.</p>
    </div>
    <div className="col-md-4 feature">
      <i className="icon-ai"></i>
      <h3>AI-Powered Farm Management</h3>
      <p>Optimize land productivity with AI-driven insights.</p>
    </div>
    <div className="col-md-4 feature">
      <i className="icon-drone"></i>
      <h3>Drone Integration</h3>
      <p>Real-time data collection for efficient land and crop management.</p>
    </div>
    <div className="col-md-4 feature">
      <i className="icon-cloud"></i>
      <h3>Cloud Computing Integration</h3>
      <p>Leverage scalable cloud computing for real-time AI analysis and seamless data processing.</p>
    </div>
    <div className="col-md-4 feature">
      <i className="icon-smart-contracts"></i>
      <h3>Smart Contracts</h3>
      <p>Automated, secure, and transparent transactions.</p>
    </div>
  </div>
</section>

  <section className="bubble-section container">
              <div className="vision-container row">
          <div className="vision-image col-md-12">
            <img src={Tokenimagesection} alt="Vision Image" width="100%" />
          </div>
        </div>
      </section>


      
      <section className="bubble-section container">
              <div className="vision-container row">
          <div className="vision-image col-md-12">
            <img src={Dronesectionimage} alt="Drone Section" width="100%" />
          </div>
        </div>
      </section>
      
                  {/* Why Now Section */}
<section className="bubble-section container">
  <h2 className="text-center">Why Now?</h2>
  <div className="row">
    <div className="col-md-4 why-now-point">
      <h4>Digital Transformation</h4>
      <p>Embrace the digital revolution in agriculture, where technology drives efficiency and profitability.</p>
    </div>
    <div className="col-md-4 why-now-point">
      <h4>Data-Driven Decisions</h4>
      <p>Utilize real-time data from drones and AI to make informed decisions, reducing risks and maximizing returns.</p>
    </div>
    <div className="col-md-4 why-now-point">
      <h4>Scalable Investments</h4>
      <p>Access scalable investment opportunities in farmland, with fractional ownership and diversified portfolios.</p>
    </div>
    <div className="col-md-4 why-now-point">
      <h4>Environmental Impact</h4>
      <p>Invest in sustainable practices that not only yield profits but also contribute to environmental conservation.</p>
    </div>
    <div className="col-md-4 why-now-point">
      <h4>Market Demand</h4>
      <p>Capitalize on the growing demand for innovative agricultural solutions and sustainable food production.</p>
    </div>
    <div className="col-md-4 why-now-point">
      <h4>Future-Proofing</h4>
      <p>Stay ahead of the curve by investing in technologies that future-proof agriculture and ensure long-term growth.</p>
    </div>
  </div>
</section>


            <section className="bubble-section container">
  <div className="vision-container row">
    <div className="vision-video col-md-6">
      <video width="100%" controls>
        <source src={DroneVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div className="vision-text col-md-6">
      <h2>Our Vision</h2>
      <p>We envision a future where real estate and farmland management are transformed by blockchain, AI, and drone technology. Our mission is to build a transparent, efficient platform that makes land ownership and investment accessible to everyone.</p>
      <p>Just as little drops of water make a mighty ocean, we believe that small contributions can lead to big change. By empowering investors and landowners with fractional ownership and real-time insights, we aim to create a thriving ecosystem that drives economic growth and innovation. Together, we can set a new standard for land management and inspire a global shift towards digital transformation in real estate and agriculture.</p>
      <p>Discover how our solution can reshape the future of land ownership. Let’s work together to turn these small drops into a powerful ocean of opportunity.</p>
    </div>
  </div>
</section>

 <section className="bubble-section container">
              <div className="vision-container row">
          <div className="vision-image col-md-12">
            <img src={Realworldbenefit} alt="Real World Benefit" width="100%" />
          </div>
        </div>
      </section>






        </main>
    );
}

export default HomePage;
