import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3';
import './InitializeContract.css'; // We'll create this CSS file

const InitializeContract = () => {
  const [formData, setFormData] = useState({
    name: '',
    symbol: '',
    totalPlots: '',
    plotValue: '',
    admin: '',
    ipnsLinks: []
  });
  const [account, setAccount] = useState('');
  const [isInitializing, setIsInitializing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contractInstances, setContractInstances] = useState({});

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error(err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'ipnsLinks') {
      const ipnsLinksArray = value.split(',').map(link => link.trim());
      setFormData({ ...formData, ipnsLinks: ipnsLinksArray });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.ipnsLinks.length !== parseInt(formData.totalPlots)) {
      setErrorMessage('The number of IPNS links must match the total number of plots.');
      return;
    }

    setIsInitializing(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Contract owner account is not available.');
      }

      await contractInstances.realEstateInstance.methods.initialize(
        formData.name,
        formData.symbol,
        parseInt(formData.totalPlots),
        parseInt(formData.plotValue),
        formData.admin,
        formData.ipnsLinks
      ).send({ from: account });

      setSuccessMessage('Contract initialized successfully!');
    } catch (error) {
      console.error(`Error initializing contract: ${error.message}`);
      setErrorMessage(`Error initializing contract: ${error.message}`);
    } finally {
      setIsInitializing(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="initialize-contract">
      <h2>Initialize Contract</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="symbol">Symbol:</label>
          <input
            type="text"
            id="symbol"
            name="symbol"
            value={formData.symbol}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="totalPlots">Total Plots:</label>
          <input
            type="number"
            id="totalPlots"
            name="totalPlots"
            value={formData.totalPlots}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="plotValue">Plot Value:</label>
          <input
            type="number"
            id="plotValue"
            name="plotValue"
            value={formData.plotValue}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="admin">Admin Address:</label>
          <input
            type="text"
            id="admin"
            name="admin"
            value={formData.admin}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ipnsLinks">IPNS Links (comma-separated):</label>
          <textarea
            id="ipnsLinks"
            name="ipnsLinks"
            value={formData.ipnsLinks.join(', ')}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" disabled={isInitializing}>
          {isInitializing ? 'Initializing...' : 'Initialize Contract'}
        </button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default InitializeContract;